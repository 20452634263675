<template>
  <v-container class="pb-2">
    <v-row>
      <v-col cols="1" align="right" offset="11">
        <v-btn icon @click.stop="toggleMenu" class="float-right">
          <v-icon x-large>mdi-menu</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <Menu ref="refMenu"></Menu>
    <h2 class="text-center">ЦЕНЫ НА МЕТАЛЛОЛОМ</h2>
          <template>
            <v-simple-table
              class="mt-6"
            >
              <template>
                <tbody>
                <tr>
                  <td>Черный металл</td>
                  <td>от 21 до 22 р/кг</td>
                </tr>
                <tr>
                  <td>Черный лом Жесть</td>
                  <td>от 19 до 20 р/кг</td>
                </tr>
                <tr>
                  <td>Черный лом резка, демонтаж</td>
                  <td>от 19 до 20 р/кг</td>
                </tr>
                <tr>
                  <td>Ванная</td>
                  <td>от 900 до 1200 р/кг</td>
                </tr>
                <tr>
                  <td>Холодильник</td>
                  <td>от 8 р/кг до 12 р/кг</td>
                </tr>
                <tr>
                  <td>Плита газовая</td>
                  <td>от 8 р/кг до 12 р/кг</td>
                </tr>
                <tr>
                  <td>Стиральная машина</td>
                  <td>от 8 р/кг до 12 р/кг</td>
                </tr>
                <tr>
                  <td>Алюминий моторный/смешанный</td>
                  <td>от 104 до 113 р/кг</td>
                </tr>
                <tr>
                  <td>Алюминиевая посуда</td>
                  <td>от 136 до 141 р/кг</td>
                </tr>
                <tr>
                  <td>Медная группа</td>
                  <td>от 604 до 630 р/кг</td>
                </tr>
                <tr>
                  <td>Латунная группа</td>
                  <td>от 351 до 356 р/кг</td>
                </tr>

                </tbody>
              </template>
            </v-simple-table>
          </template>
          <v-row>
            <v-col class="d-flex justify-center">
              <router-link to="/order/1" class="text-decoration-none d-block">
                <v-btn
                  large
                  elevation="2"
                  dark
                  color="orange lighten-1"
                  class="mt-10 custom-btn"
                >
                  Посмотреть все цены
                </v-btn>
              </router-link>
            </v-col>
          </v-row>

  </v-container>
</template>
<script>
import Menu from '../components/Menu'

export default {
  components: { Menu },
  props: ['date', 'token'],
  data: () => ({
    orders: []
  }),
  created () {
    this.orders = this.$ls.get('ordersHistory').reverse()
  },
  methods: {
    toggleMenu () {
      this.$refs.refMenu.toggle()
    },
    open (item) {
      this.$router.push('/result/' + item.token + '/' + item.date)
    }
  }
}
</script>
<style>
h1 {
  line-height: 1em;
  font-size: 26px;
  margin-bottom: 10px;
  margin-top: 20px;
}

h3 {
  line-height: 1em;
  font-size: 20px;
  margin-bottom: 10px;
  font-weight: normal;
}
</style>
